import React, { Component } from "react";
import { Helmet } from "react-helmet";
import Layout from "../layout";
import Contact from "../components/Contact/Contact";
import config from "../../data/SiteConfig";
import ContactData from "../../data/AdminContact.json";

class ContactPage extends Component {
  render() {
    return (
      <Layout>
        <div className="about-container">
          <Helmet title={`${ContactData.title} | ${config.siteTitle}`} />
          <Contact />
        </div>
      </Layout>
    );
  }
}

export default ContactPage;
