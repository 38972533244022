import React, { Component } from "react";
import "../page.css";
import ContactData from "../../../data/AdminContact.json";
import Form from "../Form/Form";

class Contact extends Component {
  render() {
    const data = ContactData;
    return (
      <div className="default-page">
        <section className="page-image-section">
          <div className="page-image-wrapper">
            <img className="page-image" alt={data.title} src={data.image} />
            <h1 className="page-title">{data.title}</h1>
          </div>
        </section>
        <div className="contact-page-content">
          {data.desc || null}
          <div className="contact-grid">
            <Form />
            <div>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3011.5191774222917!2d28.832902015413545!3d40.992008379302455!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14caa3672c462bdf%3A0xce0caeb872ac4d86!2sNef%20Atak%C3%B6y%2022!5e0!3m2!1str!2str!4v1600031713964!5m2!1str!2str"
                className="contact-map"
                frameborder="0"
                allowfullscreen=""
                aria-hidden="false"
                tabindex="0"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Contact;
