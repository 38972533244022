import React, { useState } from "react";

const Form = () => {
  function encode(data) {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
      )
      .join("&");
  }
  const [validated, setValidated] = useState(false);
  const [state, setState] = useState({});

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    }
    setValidated(true);

    if (form.checkValidity() === true)
      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({
          "form-name": "Contact_Form",
          ...state,
        }),
      });
  };
  const { email, name, phone, message, services } = useState({});

  console.log(state);
  return (
    <>
      <form
        className="form"
        netlify
        validated={validated}
        onSubmit={handleSubmit}
        name="Contact_Form"
        method="POST"
        action="/success/"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
      >
        <input
          required
          onChange={handleChange}
          placeholder="Adınız ve Soyadınız"
          type="text"
          className="forminput"
          value={name}
          style={{ borderColor: `${state.email ? "#009688" : ""}` }}
          name="name"
        />
        <input
          required
          onChange={handleChange}
          placeholder="E-Posta Adresiniz"
          type="email"
          className="forminput"
          value={email}
          style={{ borderColor: `${state.email ? "#009688" : ""}` }}
          name="email"
        />{" "}
        <br />
        <input
          required
          onChange={handleChange}
          placeholder="Telefon"
          type="number"
          className="forminput"
          value={phone}
          style={{ borderColor: `${state.email ? "#009688" : ""}` }}
          name="phone"
        />{" "}
        <br />
        <label className="form-label" for="message">
          Mesajınız:
        </label>
        <br />
        <textarea
          className="form-textarea"
          id="message"
          onChange={handleChange}
          value={message}
          name="message"
          rows="2"
          cols="auto"
        />
        <br />
        <label className="form-label" for="services">
          Size Hangi Konuda Destek Olabiliriz?
        </label>
        <br />
        <select
          onChange={handleChange}
          value={services}
          className="form-select"
          id="services"
          name="services"
        >
          <option value="Hukuk Danışmanlığı">Hukuk danışmanlığı</option>
          <option value="Arabuluculuk">Arabuluculuk</option>
        </select>
        <br />
        <button className="buttonBlack" type="submit">
          <text> {`${validated === true ? "Teşekkürler!" : "Gönder"}`}</text>
        </button>
      </form>
    </>
  );
};
export default Form;
